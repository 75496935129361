<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="!ready" />
        <div
          v-if="ready"
          :key="status"
        >
          <crud-list
            ref="crudListCotacao"
            v-model="cotacoes"
            :headers="headers"
            :slots="[
              'item.data_cotacao',
              'item.data_prevista_entrega',
              'item.status',
            ]"
            :actions="actions"
            :permission="'list_cotacao'"
            :sort-by="['status', 'data_cotacao', 'data_prevista_entrega']"
            :sort-desc="[false,true,true]"
            show-expand
          >
            <template slot="datas">
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_inicial"
                  :label="'Data Inicial'"
                  :data-max="data_final"
                  @set="handleDataFilter()"
                />
              </v-col>
              <v-col
                cols="6"
              >
                <data-field
                  v-model="data_final"
                  :label="'Data Final'"
                  :data-min="data_inicial"
                  @set="handleDataFilter()"
                />
              </v-col>
            </template>
            <template v-slot:[`item.data_cotacao`]="{ item }">
              <span>{{ moment( item.data_cotacao ).format( "DD/MM/YYYY - HH:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.data_prevista_entrega`]="{ item }">
              <span>{{ moment(item.data_prevista_entrega).format("DD/MM/YYYY - HH:mm:ss") }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="statusCotacaoColor(item.status)"
                outlined
                @click="filterStatus(item.status)"
              >
                <v-avatar left>
                  <v-icon>{{ statusCotacaoIcon(item.status) }}</v-icon>
                </v-avatar>
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td
                v-if="item.itens.length"
                :colspan="headers.length"
              >
                <v-simple-table
                  :height="$vuetify.breakpoint.xl ? '300' : '200'"
                  fixed-header
                  class="my-1"
                >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Produtos
                      </th>
                      <th class="text-center">
                        UN
                      </th>
                      <th class="text-right">
                        Quantidade
                      </th>
                      <th class="text-left">
                        Categoria
                      </th>
                      <th class="text-left">
                        Observação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="( prod, index ) in item.itens"
                      :key="index"
                    >
                      <td
                        class="text-left"
                        width="500"
                      >
                        {{ prod.codProduto + ' - ' + prod.nome_produto }}
                      </td>
                      <td
                        width="50"
                        class="text-center"
                      >
                        {{ prod.unidade_medida }}
                      </td>
                      <td
                        width="50"
                        class="text-right"
                      >
                        {{ prod.quantidade }}
                      </td>
                      <td
                        width="50"
                        class="text-left"
                      >
                        {{ prod.nome_categoria }}
                      </td>
                      <td
                        width="100"
                        class="text-left"
                      >
                        {{ prod.observacao }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </template>
            <template
              v-if="$store.getters.hasPermission('inserir_cotacao')"
              v-slot:btnCadastro
            >
              <v-col>
                <v-btn
                  color="primary"
                  rounded
                  class="text-none"
                  @click="exportarXLSX(null)"
                >
                  Exportar XLSX
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar"
                  rounded
                  @click="NOVA_COTACAO()"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
          </crud-list>
          <cotacao-dialog />
          <cotacao-logs-dialog />
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import store from '@/store'
  import CotacoesStore, { BOOTSTRAP, DELETE_COTACAO, EDIT_COTACAO, HISTORICO_COTACAO, NOVA_COTACAO } from '@/store/modules/cotacoes'
  import CotacaoStore, { CLOSE } from '@/store/modules/forms/cotacao'
  import UserProfileStore, { LOAD_NOTIFICATIONS } from '@/store/modules/userProfile'
  import JsPDF from 'jspdf'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import { statusCotacao, statusCotacaoColor, statusCotacaoString, statusCotacaoIcon } from '../../utils/constants'
  import { currencyFormatter, dateFormatter } from '../../utils/formatter'
  import { sortDateTime, sortStatusCotacao } from '../../utils/sorts'
  import CotacaoDialog from './CotacaoDialog.vue'
  import XLSX from 'xlsx'
  import CotacaoLogsDialog from './CotacaoLogsDialog.vue'

  export default {
    components: {
      CotacaoDialog,
      CotacaoLogsDialog,
    },
    props: {
      status: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        currencyFormatter,
        dateFormatter,
        statusCotacao,
        statusCotacaoColor,
        statusCotacaoIcon,
        statusCotacaoString,
        data_inicial: 'from',
        data_final: 'to',
        headers: [
          { text: '', sortable: false, groupable: false, value: 'data-table-expand' },
          { text: '', value: 'actions', sortable: false, groupable: false, width: '100' },
          { align: 'center', sortable: true, groupable: true, text: 'Status', value: 'status', width: 'auto', sort: sortStatusCotacao, floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: false, text: 'Números da Cotação', value: 'cotacao_id', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, groupable: false, text: 'Data', value: 'data_cotacao', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center' }, styleXLSX: { type: 'date' } },
          { align: 'left', sortable: true, groupable: false, text: 'Data da entrega', value: 'data_prevista_entrega', width: 'auto', sort: sortDateTime, report: true, styleReport: { halign: 'center' }, styleXLSX: { type: 'date' } },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Matrícula', value: 'cod_produtor_fornecedor', width: 'auto', report: true },
          { align: 'center', sortable: true, groupable: true, floatingfilter: true, text: 'Matr. Clube', value: 'cod_produtor_cliente', width: 'auto', report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Produtor', value: 'nome_produtor', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Fazenda', value: 'nome_fazenda', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Técnico', value: 'tecnico.nome', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Origem', value: 'Origem.descricao', width: 'auto', floatingfilter: true, report: true },
          { align: 'left', sortable: true, groupable: true, text: 'Unidade de Negócio', value: 'unidadeNegocio.nome_fantasia', width: 'auto', floatingfilter: true },
          { align: 'left', sortable: true, groupable: false, text: 'Observação', value: 'observacao', width: 'auto' },
        ],
        actions: [
          {
            title: 'Editar Cotação',
            color: 'info darken-3',
            click: item => this.EDIT_COTACAO(item),
            icon: 'mdi-pencil',
            permission: 'editar_cotacao',
          },
          {
            title: 'Histórico da Cotação',
            color: 'info darken-3',
            click: item => this.HISTORICO_COTACAO(item),
            icon: 'mdi-history',
            permission: 'historico_cotacao',
          },
          {
            title: 'Imprimir Cotação',
            color: '#D50000',
            click: item => this.exportPDF(item),
            icon: 'mdi-file-pdf',
            permission: 'imprimir_cotacao',
          },
          {
            title: 'XLSX',
            color: '#66BB6A',
            click: item => this.exportarXLSX(item),
            icon: 'mdi-microsoft-excel',
            permission: 'imprimir_cotacao',
          },
        ].filter(({ permission }) => store.getters.hasPermission(permission) && store.state.user.roles[0].slug !== 'tecnico'),
      }
    },
    computed: {
      ...mapState('cotacoes', ['ready', 'cotacoes']),
      ...mapGetters(['hasPermission']),
    },
    created () {
      if (!this.$store.state.form.cotacao) { this.$store.registerModule(['form', 'cotacao'], CotacaoStore) }
      if (!this.$store.hasModule('cotacoes')) { this.$store.registerModule('cotacoes', CotacoesStore) }
      if (!this.$store.state.userProfile) { this.$store.registerModule('userProfile', UserProfileStore) }

      const now = new Date()
      const data_i = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const data_f = new Date(now.getFullYear(), now.getMonth() + 1, 0)

      this.data_inicial = data_i.toISOString().slice(0, 10)
      this.data_final = data_f.toISOString().slice(0, 10)

      this.BOOTSTRAP({ data_inicial: data_i.toISOString().slice(0, 10), data_final: data_f.toISOString().slice(0, 10) })
    },
    updated () {
      if (this.status !== '') {
        this.filterStatus(this.status)
      }
    },
    destroyed () {
      this.$store.dispatch('userProfile/LOAD_NOTIFICATIONS')
    },
    methods: {
      ...mapActions('cotacoes', [BOOTSTRAP, EDIT_COTACAO, DELETE_COTACAO, HISTORICO_COTACAO, NOVA_COTACAO]),
      ...mapActions('userProfile', [LOAD_NOTIFICATIONS]),
      ...mapMutations('form/cotacao', [CLOSE]),
      handleDataFilter () {
        const data_i = this.data_inicial
        const data_f = this.data_final
        this.BOOTSTRAP({ data_inicial: data_i, data_final: data_f })
      },
      filterStatus (status) {
        const elemento = this.$refs.crudListCotacao.filters.status.indexOf(status)
        if (this.$refs.crudListCotacao.filters.status && elemento === -1) {
          this.$refs.crudListCotacao.filters.status.push(status)
        } else {
          this.$refs.crudListCotacao.filters.status.splice(elemento, 1)
        }
      },

      exportarXLSX (dados) {
        const wb = XLSX.utils.book_new()
        const ws_data = []
        if (dados) {
          ws_data.push(
            [
              'Cotação',
              'Técnico',
              'Origem',
              'Unidade de Negócio',
              'Fazenda',
              'Matricula Clube',
              'Matricula',
              'Produtor',
              'Quantidade de Parcelas',
              'Observação',
            ],
          )
          ws_data.push(
            [
              dados.cotacao_id,
              dados.tecnico ? dados.tecnico.nome : 'Não possui Técnico atribuido',
              dados.Origem ? dados.Origem.descricao : '',
              dados.unidadeNegocio.cod_clube + ' - ' + dados.unidadeNegocio.nome_fantasia,
              dados.nome_fazenda,
              dados.cod_produtor_cliente,
              dados.cod_produtor_fornecedor,
              dados.nome_produtor,
              dados.parcelas,
              dados.observacao,
            ],
          )
          ws_data.push(['', '', '', '']) // Espaço em branco para simular separação

          ws_data.push(['Código', 'Descrição', 'UN', 'Qtd.', 'Vol.', 'Preço', 'Preço Desc.', 'Vlr. Bruto', 'Vlr. Liquido', 'Observação'])
          dados.itens.forEach(item => {
            ws_data.push([
              item.codProduto,
              item.nome_produto,
              item.unidade_medida,
              item.quantidade,
              item.volume,
              parseFloat(item.valor_unitario),
              item.valor_total / item.quantidade,
              parseFloat(item.valor_subtotal),
              parseFloat(item.valor_total),
              item.observacao ? item.observacao : '',
            ])
          })
        } else {
          const registros = this.$refs.crudListCotacao.valueFiltered
          ws_data.push([
            'Status',
            'Cotação',
            'Data Cotação',
            'Matricula',
            'Matricula Clube',
            'Nome Produtor',
            'Nome Fazenda',
            'Municipio',
            'Técnico',
            'Unidade de Negocio',
            'Parcelas',
            'Código Produto',
            'Descrição Produto',
            'Unidade de Medida',
            'Quantidade',
            'Preço Unitario',
            'Valor Bruto',
            'Desconto Negociado',
            'Valor Desconto',
            'Valor Liquido',
            'Observação',
          ])
          if (registros.length > 0) {
            registros.forEach(cotacao => {
              if (cotacao.itens.length === 0) {
                ws_data.push([
                  cotacao.status,
                  cotacao.cotacao_id ? cotacao.cotacao_id : '',
                  { v: cotacao.data_cotacao, t: 'd' },
                  cotacao.cod_produtor_fornecedor ? cotacao.cod_produtor_fornecedor : '',
                  cotacao.cod_produtor_cliente ? cotacao.cod_produtor_cliente : '',
                  cotacao.nome_produtor ? cotacao.nome_produtor : '',
                  cotacao.nome_fazenda ? cotacao.nome_fazenda : '',
                  cotacao.cidade_fazenda ? cotacao.cidade_fazenda : '',
                  cotacao.tecnico ? cotacao.tecnico.nome : '',
                  cotacao.unidadeNegocio.cod_clube ? cotacao.unidadeNegocio.cod_clube + ' - ' + (cotacao.unidadeNegocio.nome_fantasia ? cotacao.unidadeNegocio.nome_fantasia : '') : '',
                  cotacao.parcelas ? cotacao.parcelas : '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  '',
                  cotacao.observacao ? cotacao.observacao : '',
                ])
              } else {
                cotacao.itens.forEach(item => {
                  ws_data.push([
                    cotacao.status,
                    cotacao.cotacao_id ? cotacao.cotacao_id : '',
                    { v: cotacao.data_cotacao, t: 'd' },
                    cotacao.cod_produtor_fornecedor ? cotacao.cod_produtor_fornecedor : '',
                    cotacao.cod_produtor_cliente ? cotacao.cod_produtor_cliente : '',
                    cotacao.nome_produtor ? cotacao.nome_produtor : '',
                    cotacao.nome_fazenda ? cotacao.nome_fazenda : '',
                    cotacao.cidade_fazenda ? cotacao.cidade_fazenda : '',
                    cotacao.tecnico ? cotacao.tecnico.nome : '',
                    cotacao.unidadeNegocio.cod_clube ? cotacao.unidadeNegocio.cod_clube + ' - ' + (cotacao.unidadeNegocio.nome_fantasia ? cotacao.unidadeNegocio.nome_fantasia : '') : '',
                    { v: cotacao.parcelas, t: 'n' },
                    item.codProduto ? item.codProduto : '',
                    item.nome_produto ? item.nome_produto : '',
                    item.unidade_medida,
                    { v: item.quantidade, t: 'n' },
                    { v: item.valor_unitario, t: 'n' },
                    { v: item.valor_subtotal, t: 'n' },
                    { v: item.desconto_negociado, t: 'n' },
                    { v: item.valor_desconto, t: 'n' },
                    { v: item.valor_total, t: 'n' },
                    cotacao.observacao ? cotacao.observacao : '',
                  ])
                })
              }
            })
          }
        }

        const ws = XLSX.utils.aoa_to_sheet(ws_data)

        const colWidths = ws_data[0].map((_, colIndex) => ws_data.reduce((maxWidth, currentRow) => {
          const cellValue = currentRow[colIndex]
          const cellWidth = (typeof cellValue === 'string' ? cellValue.length : 0)
          return Math.max(maxWidth, cellWidth)
        }, 0))

        ws['!cols'] = colWidths.map(width => ({ wch: width + 2 }))
        XLSX.utils.book_append_sheet(wb, ws, 'Relatório')
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },

      exportPDF (dados) {
        const spaceLine = 7
        const fontSize = 10
        const fontTitleSize = fontSize * 2
        const margin = { left: 10, right: 10, bottom: 10 }

        function getY (y) {
          if ((y + fontSize) > docHeight) {
            doc.addPage()
            i = 1
            return fontSize
          } else {
            return y
          }
        }

        function formataMoney (valor) {
          valor = valor.toFixed(2)
          valor = valor.toString()
          valor = valor.replace('.', ',')
          valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
          return valor
        }

        function addFooter (pageNumber) {
          doc.setFontSize(fontSize - 1)
          doc.text(dateNow, margin.left - 5, docHeight - margin.bottom)
          doc.text('https://clubedoprodutor.esteiogestao.com.br', docWidth / 2, docHeight - margin.bottom, { align: 'center' })
          doc.text('Página ' + pageNumber + ' / ' + totalPages, docWidth + 17, docHeight - margin.bottom, { align: 'right' })
        }
        function addHeader () {
          doc.addImage(window.location.origin + '/img/logo_branca.png', 'png', margin.left, 5, 52, 18)
          doc.setFontSize(fontTitleSize)
          doc.text('Cotação', docWidth / 2, 15, { align: 'center' })
          doc.setFontSize(fontSize)
          doc.text('Data: ' + dateFormatter(dados.data_cotacao), docWidth - margin.right, getY(++i * spaceLine), { align: 'right' })
          doc.text(dados.status, docWidth / 2, getY(i * spaceLine), { align: 'center' })
          doc.line(margin.left, 24, docWidth - margin.right, 24)
          doc.autoTable({
            theme: 'plain',
            startY: 25,
            styles: { textColor: 20, fillColor: [255, 255, 255] },
            body: [
              ['Nº Cotação: ' + dados.cotacao_id, 'Técnico: ' + (dados.tecnico ? dados.tecnico.nome : 'Não possui Técnico atribuido'), 'Unidade de Negócio: ' + dados.unidadeNegocio.cod_clube + ' - ' + dados.unidadeNegocio.nome_fantasia],
              ['Matricula: ' + dados.cod_produtor_fornecedor, 'Produtor: ' + dados.nome_produtor],
              ['Matricula Clube: ' + dados.cod_produtor_cliente, 'Fazenda: ' + dados.nome_fazenda],
            ],
            didParseCell: (data) => {
              if (data.row.index === 0) {
                data.cell.styles.cellPadding = { top: 1, right: 1.7638888888888886, bottom: 1, left: 1.7638888888888886 }
              } else {
                data.cell.styles.cellPadding = { top: 0.7, right: 1.7638888888888886, bottom: 1, left: 1.7638888888888886 }
              }
            },
          })
        }

        var doc = new JsPDF({
          orientation: 'landscape',
        })
        const dateNow = (new Date()).toLocaleString()
        const docWidth = doc.internal.pageSize.width
        const docHeight = doc.internal.pageSize.height
        var totalPages = '{total_pages_count_string}'
        var i = 2
        doc.autoTable({
          theme: 'plain',
          columnStyles: {
            desconto_negociado: { cellWidth: 21, halign: 'right' },
            preco_desconto: { halign: 'right' },
            quantidade: { halign: 'right' },
            volume: { halign: 'right' },
            previsao_compra: { halign: 'right' },
          },
          styles: { lineColor: [0, 0, 0] },
          headStyles: { lineWidth: { top: 0.2, right: 0, bottom: 0.2, left: 0 }, quantidade: { halign: 'right' } },
          columns: [
            { title: 'Código', dataKey: 'codProduto' },
            { title: 'Descrição', dataKey: 'nome_produto' },
            { title: 'UN', dataKey: 'unidade_medida' },
            { title: 'Qtd.', dataKey: 'quantidade' },
            { title: 'Vol.', dataKey: 'volume' },
            { title: 'Preço', dataKey: 'valor_unitario' },
            { title: 'Desc. %', dataKey: 'desconto_negociado' },
            { title: 'Vlr. Bruto', dataKey: 'valor_subtotal' },
            { title: 'Vlr. Desc.', dataKey: 'valor_desconto' },
            { title: 'Vlr. Liquido', dataKey: 'valor_total' },
            { title: 'Observação', dataKey: 'observacao' },
          ],
          body: dados.itens,

          margin: { top: 43, left: 10, right: 10, bottom: 10 },
          didParseCell: (data) => {
            const trocarPonto = data.column.dataKey === 'valor_unitario' || data.column.dataKey === 'valor_subtotal' || data.column.dataKey === 'valor_desconto' || data.column.dataKey === 'valor_total' || data.column.dataKey === 'desconto_negociado'
            if (trocarPonto) {
              data.cell.styles.halign = 'right'
              if (data.row.section === 'body' && data.cell.text) {
                data.cell.text = formataMoney(parseFloat(data.cell.text))
              }
            }
          },

          willDrawPage: () => {
            addHeader()
          },
          didDrawPage: (data) => {
            addFooter(data.pageNumber)
          },

        })
        const finalY = doc.lastAutoTable.finalY
        doc.line(margin.left, finalY, docWidth - margin.right, finalY)
        doc.setFontSize(fontSize)
        doc.setFont('helvetica', 'normal')
        const observacao = dados.observacao ? dados.observacao : ''
        doc.text('Observação: ' + observacao, margin.left + 2, finalY + 6, { maxWidth: 220 })
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPages)
        }
        doc.save('Cotacao_' + dados.cotacao_id + '.pdf')
      },
    },
  }
</script>
