<template>
  <v-dialog
    v-model="dialog"
    max-width="1700px"
    max-height="800px"
    width="90%"
    scrollable
    persistent
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-toolbar-title
          v-if="loading"
        >
          {{ 'Cotação' }}
        </v-toolbar-title>
        <v-toolbar-title
          v-else
        >
          {{ id ? 'Editando Cotação' : 'Nova Cotação' }}
        </v-toolbar-title>
        <v-spacer />

        <v-btn
          icon
          dark
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pb-1">
        <base-loading v-if="loading" />
        <div v-else>
          <v-form
            ref="form"
            dense
            lazy-validation
          >
            <v-card
              elevation="0"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="cotacao_id"
                    dense
                    label="Cotação Nº"
                    class="text-direita"
                    readonly
                    outlined
                    rounded
                    hide-details
                    style="font-weight: bold;"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <data-time-picker
                    v-model="data_cotacao"
                    label="Data"
                    readonly
                    disabled
                  />
                </v-col>
                <v-col
                  v-if="id"
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <data-field
                    v-model="data_limite"
                    :label="status == statusCotacao.aprovado ? 'Data limite *' : 'Data limite'"
                    :rules="status == statusCotacao.aprovado ? [rules.required] : []"
                    :data-min="data_cotacao"
                    hide-details
                    style-text-field=""
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    :value="currencyFormatter(valor_total ?? 0)"
                    dense
                    label="Valor total"
                    class="text-direita"
                    prefix="R$"
                    readonly
                    hide-details
                    outlined
                    rounded
                    style="font-weight: bold;"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-autocomplete
                    v-model="parcelas"
                    hide-details
                    dense
                    autocomplete="off"
                    :items="listParcelamento"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.required]"
                    label="Parcelamento"
                    validate-on-blur
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <v-text-field
                    v-if="id"
                    v-model="nome_fazenda"
                    dense
                    label="Fazenda"
                    hide-details
                    readonly
                    outlined
                    rounded
                  />
                  <v-autocomplete
                    v-else
                    v-model="fazenda_id"
                    dense
                    autocomplete="off"
                    :items="fazendas"
                    item-text="razao_social"
                    item-value="id"
                    :rules="[rules.required]"
                    label="Fazenda *"
                    validate-on-blur
                    hide-details
                    outlined
                    rounded
                    @change="selectFazenda"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="5"
                >
                  <v-text-field
                    v-if="id"
                    v-model="nome_produtor"
                    dense
                    hide-details
                    label="Produtor"
                    readonly
                    outlined
                    rounded
                  />

                  <v-autocomplete
                    v-else
                    v-model="produtor_id"
                    dense
                    autocomplete="off"
                    :items="listProdutores"
                    item-text="nome_razao_social"
                    item-value="id"
                    hide-details
                    :rules="[rules.required]"
                    label="Produtor *"
                    validate-on-blur
                    outlined
                    rounded
                    :disabled="!fazenda_id"
                    @change="selectProdutor"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="6"
                  sm="6"
                >
                  <v-text-field
                    v-model="statusPrevious"
                    dense
                    label="Status"
                    readonly
                    hide-details
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="1"
                  sm="1"
                >
                  <v-icon
                    v-if="!$vuetify.breakpoint.xs"
                    large
                    class="d-flex align-center"
                  >
                    mdi-chevron-triple-right
                  </v-icon>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-autocomplete
                    v-model="status"
                    dense
                    autocomplete="off"
                    :items="listStatus"
                    :rules="[rules.required]"
                    label="Status"
                    validate-on-blur
                    hide-details
                    outlined
                    rounded
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                >
                  <data-field
                    v-model="data_prevista_entrega"
                    label="Data prevista de entrega"
                    :data-min="new Date().toISOString().substr(0, 10)"
                    :style-text-field="``"
                    hide-details
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="observacao"
                    maxlength="255"
                    dense
                    no-resize
                    height="65"
                    label="Observação"
                    :rules="[...(observacaoRequired() ? [rules.required] : [])]"
                    validate-on-blur
                    outlined
                    hide-details
                    rounded
                  />
                </v-col>
              </v-row>
            </v-card>
            <v-container
              fluid
              class="mt-1"
            >
              <v-row>
                <v-col cols="4">
                  <cotacao-item-dialog
                    :key="unidade_negocio_id"
                    :cotacao-id="unidade_negocio_id"
                    @submit="addCotacaoItem"
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-tabs
              v-model="tabs"
              icons-and-text
            >
              <v-tabs-slider />
              <v-tab
                style="font-size: smaller;"
              >
                Produtos
                <v-icon>
                  mdi-package-variant-closed
                </v-icon>
              </v-tab>
              <v-tab
                style="font-size: smaller;"
              >
                Parcelamento
                <v-icon>
                  mdi-cash-multiple
                </v-icon>
              </v-tab>
              <v-tab
                style="font-size: smaller;"
                @click="downloadImage(signature_id)"
              >
                Tipo de atendimento
                <v-icon>mdi-face-agent</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tabs"
              mandatory
            >
              <v-tab-item>
                <v-container
                  fluid
                  class="pt-1 pl-1 pr-1 pb-1"
                >
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <crud-list
                        v-model="itens"
                        :headers="headers"
                        :actions="actions"
                        :slots="['item.quantidade', 'item.valor_unitario', 'item.valor_subtotal', 'item.valor_desconto', 'item.valor_total', 'item.previsao_compra', 'item.desconto_negociado']"
                        :show-search="false"
                        :item-height="$vuetify.breakpoint.xl ? '202' : '200'"
                      >
                        <template v-slot:[`item.valor_subtotal`]="{ item }">
                          <span>{{ currencyFormatter((item.valor_subtotal)) }}</span>
                        </template>
                        <template v-slot:[`item.valor_desconto`]="{ item }">
                          <span>{{ currencyFormatter(item.valor_desconto) }}</span>
                        </template>
                        <template v-slot:[`item.valor_total`]="{ item }">
                          <span>{{ currencyFormatter(item.valor_total) }}</span>
                        </template>
                        <template v-slot:[`item.quantidade`]="{ item }">
                          <v-edit-dialog
                            :return-value.sync="item.quantidade"
                            large
                            :save-text="'Salvar'"
                            :cancel-text="'Cancelar'"
                            @save="save({
                              item,
                              field: 'quantidade',
                              value: item.quantidade,
                            })"
                          >
                            <span>{{ item.quantidade }}</span>
                            <v-icon
                              left
                              small
                              class="pl-2"
                              color="info darken-3"
                            >
                              mdi-pencil
                            </v-icon>
                            <template v-slot:input>
                              <v-text-field
                                v-model.number="item.quantidade"
                                v-mask="'##########'"
                                v-only-number
                                label="Editar quantidade"
                                autofocus
                                single-line
                                outlined
                                dense
                                rounded
                                class="mt-5"
                              />
                            </template>
                          </v-edit-dialog>
                        </template>
                        <template v-slot:[`item.valor_unitario`]="{ item }">
                          <v-edit-dialog
                            :return-value.sync="item.valor_unitario"
                            large
                            :save-text="'Salvar'"
                            :cancel-text="'Cancelar'"
                            @save="save({
                              item,
                              field: 'valor_unitario',
                              value: item.valor_unitario,
                            })"
                          >
                            <span>{{ currencyFormatter(item.valor_unitario) }}</span>
                            <v-icon
                              left
                              small
                              class="pl-2"
                              color="info darken-3"
                            >
                              mdi-pencil
                            </v-icon>
                            <template v-slot:input>
                              <base-vuetify-money
                                v-model="item.valor_unitario"
                                :options="money"
                                maxlength="10"
                                label="Valor unitário (R$)"
                              />
                            </template>
                          </v-edit-dialog>
                        </template>
                      </crud-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-card
                  outlined
                  class="mt-1"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-card
                        min-height="258"
                        elevation="2"
                      >
                        <v-simple-table
                          :height="$vuetify.breakpoint.xl ? '258' : '200'"
                          fixed-header
                          elevation="0"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-right"
                                >
                                  Parcela
                                </th>
                                <th
                                  class="text-right"
                                >
                                  Valor da Parcela
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in parcelamento.parcelado1"
                                :key="index"
                              >
                                <td

                                  style="text-align: right;"
                                >
                                  {{ item.parcela1 + 'º' }}
                                </td>
                                <td

                                  style="text-align: right;"
                                >
                                  {{ 'R$ ' + item.valor1 }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-card
                        elevation="2"
                        min-height="258"
                      >
                        <v-simple-table
                          :height="$vuetify.breakpoint.xl ? '258' : '200'"
                          fixed-header
                          elevation="0"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th
                                  class="text-right"
                                >
                                  Parcela
                                </th>
                                <th
                                  class="text-right"
                                >
                                  Valor da Parcela
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, index) in parcelamento.parcelado2"
                                :key="index"
                              >
                                <td

                                  style="text-align: right;"
                                >
                                  {{ item.parcela2 + 'º' }}
                                </td>
                                <td

                                  style="text-align: right;"
                                >
                                  {{ 'R$ ' + item.valor2 }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card>
                  <v-row
                    class="mt-1 mx-4"
                  >
                    <v-col cols="2">
                      <v-radio-group
                        v-model="tipo_atendimento"
                        :readonly="!!id"
                        :rules="!id ? [rules.required] : []"
                      >
                        <v-radio
                          v-if="!!id"
                          label="Presencial"
                          :value="0"
                        />
                        <v-radio
                          label="Telefone"
                          :value="1"
                        />
                        <v-radio
                          label="Online"
                          :value="2"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 0 && signature"
                      cols="12"
                      md="3"
                      class="justify-center text-center"
                    >
                      <h4 class="text-h4 primary--text">
                        Assinatura do produtor
                      </h4>
                      <v-img
                        max-width="400px"
                        class="rounded-xl"
                        :src="signature"
                        style="object-fit: contain; border: 0.5px solid #42424220;"
                      />
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 0 && !signature && signature_id"
                      cols="12"
                      md="3"
                      class="justify-center text-center"
                    >
                      <base-loading />
                    </v-col>

                    <v-col
                      v-if="tipo_atendimento == 1"
                      cols="3"
                      class="mt-4"
                    >
                      <v-row class="d-block">
                        <v-col>
                          <data-time-picker
                            v-model="data_atendimento"
                            label="Data do atendimento *"
                            :style-text-field="``"
                            :readonly="!!id"
                            :rules="!id ? [rules.required] : []"
                          />
                        </v-col>

                        <v-col>
                          <v-text-field
                            v-model="observacao_atendimento"
                            dense
                            label="Observação *"
                            hide-details="auto"
                            outlined
                            :readonly="!!id"
                            :rules="!id ? [rules.required] : []"
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions
        class="justify-end"
      >
        <v-btn
          class="text-none text-white"
          color="error"
          rounded
          @click="cancel"
        >
          <v-icon
            dark
            left
          >
            mdi-minus-circle
          </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          class="text-none text-white"
          color="success"
          depressed
          rounded
          :loading="loading"
          :disabled="loading"
          @click="submit"
        >
          <v-icon
            dark
            left
          >
            mdi-checkbox-marked-circle
          </v-icon>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { CLOSE, SET_STATE, SUBMIT, PUSH_ITEM, DELETE_ITEM, DELETE_SUBMIT, CREATE_COTACAO } from '../../store/modules/forms/cotacao'
  import { LOAD_BATCH } from '../../store/modules/produtores'
  import { LOAD_FAZENDAS } from '../../store/modules/fazendas'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
  import { statusCotacao, statusCotacaoFitString } from '../../utils/constants'
  import { currencyFormatter, dateFormatter } from '../../utils/formatter'
  import { stringToNumber } from '../../utils/parser'
  import { sortCurrency } from '../../utils/sorts'
  import CotacaoItemDialog from './CotacaoItemDialog.vue'
  import axios from '../../api/axios'

  export default {
    name: 'CotacaoForm',
    components: {
      CotacaoItemDialog,
    },

    directives: {
      onlyNumber: {
        bind (el, binding) {
          el.addEventListener('input', () => {
            const inputElement = el.querySelector('input')
            inputElement.value = inputElement.value.replace(/\D/g, '')
          })
        },
      },
    },

    data () {
      return {
        tabs: null,
        statusCotacaoFitString,
        statusCotacao,
        currencyFormatter,
        sortCurrency,
        money: {
          locale: 'pt-BR',
          precision: 2,
          prefix: 'R$ ',
        },
        actions: [
          {
            icon: 'mdi-delete',
            title: 'Excluir',
            click: item => {
              this.DELETE_ITEM(item).then(() => {
                const index = this.itens.findIndex(i => {
                  if (i.tmp_id && item.tmp_id) return i.tmp_id === item.tmp_id
                })
                if (index === -1) return
                this.itens.splice(index, 1)
                this.parcelamentos()
              })
            },
            color: '#D50000',
          },
        ],

        headers: [
          { align: 'center', sortable: false, text: 'Ações', value: 'actions', width: 'auto' },
          { align: 'left', sortable: true, text: 'Produto', value: 'nome_produto', width: 'auto' },
          { align: 'center', sortable: true, text: 'UN', value: 'unidade_medida', width: 'auto' },
          { align: 'right', sortable: true, text: 'Quantidade', value: 'quantidade', width: 'auto' },
          { align: 'right', sortable: true, text: 'Volume', value: 'volume', width: 'auto' },
          { align: 'right', sortable: true, text: 'Preço Unitário (R$)', value: 'valor_unitario', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Bruto (R$)', value: 'valor_subtotal', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Desc. Negociado', value: 'desconto_negociado', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Desconto (R$)', value: 'valor_desconto', width: 'auto', sort: sortCurrency },
          { align: 'right', sortable: true, text: 'Valor Líquido (R$)', value: 'valor_total', width: 'auto', sort: sortCurrency },
          { align: 'left', sortable: true, text: 'Categoria', value: 'nome_categoria', width: 'auto' },
          { align: 'left', sortable: true, text: 'Observação', value: 'observacao', width: 'auto' },
        ],

        arrayParcelamento: [],
        MAX_PARCELAS: 12,
      }
    },
    computed: {
      ...mapState('form/cotacao', ['loading', 'rules', 'dialog']),
      ...mapFields('form', [
        'data.id',
        'data.user_id',
        'data.cotacao_id',
        'data.unidade_negocio_id',
        'data.nome_produtor',
        'data.nome_fazenda',
        'data.statusPrevious',
        'data.status',
        'data.data_cotacao',
        'data.observacao',
        'data.resposta',
        'data.valor_total',
        'data.parcelas',
        'data.produtor_id',
        'data.fazenda_id',
        'data.data_limite',
        'data.data_prevista_entrega',
        'data.tipo_atendimento',
        'data.data_atendimento',
        'data.observacao_atendimento',
        'data.signature_id',
      ]),
      ...mapMultiRowFields('form', [
        'data.logs',
        'data.itens',
      ]),
      ...mapState('produtores', ['produtores']),
      ...mapState('fazendas', ['fazendas', 'fazendasProdutor']),
      listStatus () {
        const arrayStatus = []
        this.statusCotacaoFitString.map(item => {
          if (item !== this.statusPrevious) arrayStatus.push(item)
        })

        const nullableItem = this.itens.map(item => !!(Number(item.valor_total))).filter(item => item === false)

        if (nullableItem.length || !this.id || !this.itens.length) arrayStatus.splice(0, 1)

        return arrayStatus
      },
      produtosCotacao () {
        return this.itens ? this.itens : []
      },
      parcelamento () {
        const parcelado1 = []
        const parcelado2 = []
        if (this && this.parcelas) {
          if (this.parcelas > 0) {
            let soma = 0
            for (let i = 0; i < this.parcelas; i++) {
              const valor = (this.valor_total / this.parcelas)
              soma += parseFloat(valor.toFixed(2))
              if (i < 6) {
                if (soma > this.valor_total) {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter((valor - (soma - this.valor_total))) })
                } else if (soma < this.valor_total && i === this.parcelas - 1) {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter((valor + (this.valor_total - soma))) })
                } else {
                  parcelado1.push({ parcela1: i + 1, valor1: currencyFormatter(valor) })
                }
              } else {
                if (soma > this.valor_total) {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter((valor - (soma - this.valor_total))) })
                } else if (soma < this.valor_total && i === this.parcelas - 1) {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter((valor + (this.valor_total - soma))) })
                } else {
                  parcelado2.push({ parcela2: i + 1, valor2: currencyFormatter(valor) })
                }
              }
            }
          }
        }
        return { parcelado1, parcelado2 }
      },
      listParcelamento () {
        return this.parcelamentos()
      },

      listProdutores () {
        if (!this.fazenda_id) return []

        const fazenda = this.fazendas.find(fazenda => fazenda.id === this.fazenda_id).produtores.map(produtor => produtor.id)

        if (!fazenda.length) return []

        return this.produtores.filter((produtor) => fazenda.includes(produtor.id))
      },
    },

    created () {
      this.LOAD_BATCH()
      this.LOAD_FAZENDAS()
    },

    methods: {
      stringToNumber,
      ...mapMutations('form/cotacao', [CLOSE, SET_STATE]),
      ...mapActions('form/cotacao', [SUBMIT, PUSH_ITEM, DELETE_ITEM, DELETE_SUBMIT, CREATE_COTACAO]),
      ...mapActions('produtores', [LOAD_BATCH]),
      ...mapActions('fazendas', [LOAD_FAZENDAS]),
      observacaoRequired () {
        return this.status === this.statusCotacao.cancelado
      },
      submit () {
        if (!this.itens.length) return this.DELETE_SUBMIT()

        if (!this.$refs.form.validate()) {
          return window.Toast.fire('Atenção', 'Preencha todos os campos obrigatórios', 'warning')
        }

        if (this.tipo_atendimento !== 0 && this.tipo_atendimento !== 1 && this.tipo_atendimento !== 2) {
          this.tabs = 2
          window.Toast.fire('Atenção', 'Preencha os campos de tipo de atendimento', 'warning')
          return this.$refs.form.validate()
        }

        if (!this.id) return this.CREATE_COTACAO()

        return this.SUBMIT()
      },
      cancel () {
        this.CLOSE()
      },
      formataData (date) {
        return dateFormatter(date)
      },
      save (event) {
        event.item.valor_subtotal = event.item.valor_unitario * event.item.quantidade
        const valor_total = event.item.valor_unitario * event.item.quantidade
        event.item.valor_subtotal = valor_total
        event.item.valor_total = valor_total

        this.valor_total = this.produtosCotacao.reduce((acc, item) => acc + Number(item.valor_total), 0)

        this.parcelamentos()
      },
      addCotacaoItem (item) {
        this.PUSH_ITEM(item)

        this.valor_total = this.produtosCotacao.reduce((acc, item) => acc + Number(item.valor_total), 0)
        this.parcelamentos()
      },

      parcelamentos () {
        const arrayParcelamento = []

        if (!Number(this.valor_total)) return arrayParcelamento

        for (let i = 1; i <= this.MAX_PARCELAS; i++) {
          arrayParcelamento.push({
            text: `${i} x R$ ${currencyFormatter((this.valor_total / i))}`,
            value: i,
          })
        }

        return arrayParcelamento
      },

      selectFazenda (fazendaId) {
        const fazenda = this.fazendas.find(fazenda => fazenda.id === fazendaId)
        this.unidade_negocio_id = fazenda.unidade_negocio_id
      },

      selectProdutor (produtor) {},

      async downloadImage (signature_id) {
        if (!signature_id) return

        const url = `${axios.defaults.baseURL}/public/image/cotacao/signatures/${this.signature_id}`

        const response = await axios.get(url, { responseType: 'blob' })
        const reader = new FileReader()
        reader.readAsDataURL(response.data)
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
            canvas.width = img.height
            canvas.height = img.width
            ctx.translate(canvas.width / 2, canvas.height / 2)
            ctx.rotate(90 * Math.PI / 180)
            ctx.drawImage(img, -img.width / 2, -img.height / 2)

            this.signature = canvas.toDataURL('image/png')
          }
        }
      },
    },
  }
</script>

<style scoped>
  .v-tabs .v-tabs-bar {
    height: 50px;
  }
</style>
